<template>
  <card-conf
    ref="card"
    keys="alert_active"
    :title="$tc('reminder',2)"
    :activeted="false"
    :size="750"
    icon="mdi-bell-ring-outline"
  >
    <template>
      <v-row justify="center">
        <v-col cols="12">
          <h3
            class="text-center font-weight-regular text-uppercase text-decoration-none"
          >
            {{ $t("notifications_system") }}
          </h3>
        </v-col>
        <v-col
          cols="7"
          md="4"
          v-for="c in channels.filter((c) => c.id !== 1)"
          :key="c.id"
        >
          <base-border-card
            :disabled_class="!channelActive(c)"
            :title="c.channel_name"
            :two_lines="true"
            :icon="
              c.id == 2 ? '$email' : c.id == 3 ? '$iphone' : 'mdi-whatsapp'
            "
            :disabled="c.id == 4"
          >
            <template slot="corner_action">
              <v-btn
                icon
                @click="activateChannel(c)"
                :disabled="c.id == 4"
                elevation="0"
              >
                <v-icon :size="20" v-if="channelActive(c)">$unlock</v-icon>
                <v-icon :size="20" v-else>$lock</v-icon>
              </v-btn>
            </template>
          </base-border-card>
          <v-row justify="center">
            <ChannelInform
              :channel="c"
              v-if="c.id !== 2"
              :active="!channelActive(c)"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <h3
            class="text-center font-weight-regular text-uppercase text-decoration-none"
          >
            {{ $t("notifications_configuration") }}
          </h3>
        </v-col>
        <v-col cols="7" md="4">
          <Internal />
        </v-col>
        <v-col cols="7" md="4">
          <Artist />
        </v-col>
        <v-col cols="7" md="4">
          <Customer />
        </v-col>
      </v-row>
      <form
        action
        data-vv-scope="user-form"
        @submit.prevent="validateForm('user-form')"
        v-if="false"
      >
        <v-row align="center">
          <v-col cols="12" md="10" class="py-0">
            <h3 class="primary--text" style="font-weight: normal">
              {{ $t("alert.notifications_appointments") }}
            </h3>
          </v-col>
          <v-col class="py-0" cols="12" md="7">
            {{ $t("alert.alert_sms") }}
          </v-col>
          <v-col cols="5" class="py-0" md="2">
            <v-select
              :items="items"
              single-line
              dense
              outlined
              hide-details
              disabled
              dosa
              v-model="alert_sms"
            ></v-select>
          </v-col>
          <v-col class="py-0" cols="12" md="7">
            {{ $t("alert.alert_ask") }}
          </v-col>
          <v-col cols="5" class="py-0" md="2">
            <v-select
              :items="items"
              single-line
              dense
              outlined
              hide-details
              dosa
              disabled
              v-model="alert_email"
            ></v-select>
          </v-col>
          <v-col cols="12" md="11" class="py-0 pt-2">
            <h3 class="primary--text" style="font-weight: normal">
              {{ $t("alert.alert_rec") }}
            </h3>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            {{ $t("alert.alert_days_0_0") }}
            <v-select
              outlined
              dense
              :items="days(alert_days_1 || 1, 30)"
              v-model="alert_days_0"
              hide-details
              class="inline-input"
            ></v-select>
            {{ $t("alert.alert_days_0_1") }}
          </v-col>

          <v-col cols="12" md="12" class="py-0">
            {{ $t("alert.alert_days_1_0") }}
            <v-select
              outlined
              dense
              :items="days(1, alert_days_0 || 30)"
              v-model="alert_days_1"
              class="inline-input"
              hide-details
            ></v-select>
            {{ $t("alert.alert_days_1_1") }}
          </v-col>
        </v-row>
      </form>
    </template>
  </card-conf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AlertsCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
    Internal: () =>
      import("@/components/user/settings/configuracion/alerts/Internal"),
    Artist: () =>
      import("@/components/user/settings/configuracion/alerts/Artist"),
    Customer: () =>
      import("@/components/user/settings/configuracion/alerts/Customer"),

    ChannelInform: () =>
      import("@/components/user/settings/configuracion/alerts/ChannelInform"),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    channelActive(c) {
      let a = this.active_channels.find((ac) => ac.id == c.id);
      if (a) return true;
      return false;
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
    days(start, end) {
      if (start === "-") start = 1;
      if (end === "-") end = 30;
      const days = [];
      for (let i = start; i <= end; i++) days.push(i);
      return ["-", ...days];
    },
    ...mapActions("notifications", [
      "getChannels",
      "getActiveChannels",
      "postChannels",
    ]),
    fetch() {
      this.fetchChannels();
      this.fetchActiveChannels();
    },
    fetchChannels() {
      this.getChannels().then((response) => {
        this.channels = response;
      });
    },
    fetchActiveChannels() {
      this.getActiveChannels().then((response) => {
        this.active_channels = response;
      });
    },
    activateChannel(c) {
      this.$confirm(
        this.channelActive(c)
          ? this.$t("deactivate_external_service", { name: c.channel_name })
          : this.$t("activate_external_service", { name: c.channel_name })
      ).then(() => {
        this.postChannels({ channel_id: c.id }).then((response) => {
          this.active_channels = response;
        });
      });
    },
  },
  data() {
    return {
      disabled: false,
      channels: [],
      active_channels: [],
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  computed: {
    alert_days_0: {
      get() {
        return this.$store.getters["auth/getSetting"]("alert_days_0");
      },
      set(value) {
        //llamamos a la function de crear setting
        this.settingUpdate({ key: "alert_days_0", value }).then((data) => {
          this.$alert(this.$t("save_ok"));
          //console.log(data);
        });
      },
    },
    alert_days_1: {
      get() {
        return this.$store.getters["auth/getSetting"]("alert_days_1");
      },
      set(value) {
        //llamamos a la function de crear setting
        this.settingUpdate({ key: "alert_days_1", value }).then((data) => {
          this.$alert(this.$t("save_ok"));
          //console.log(data);
        });
      },
    },
    alert_sms: {
      get() {
        return this.$store.getters["auth/getSetting"]("alert_sms");
      },
      set(value) {
        //llamamos a la function de crear setting
        this.settingUpdate({ key: "alert_sms", value }).then((data) => {
          if (value == 0) {
            this.$alert(this.$t("disable_ok"));
          } else {
            this.$alert(this.$t("alert_sms_success"));
          }
        });
      },
    },
    alert_email: {
      get() {
        return 1; //return this.$store.getters["auth/getSetting"]("alert_email");
      },
      set(value) {
        //llamamos a la function de crear setting
        this.settingUpdate({ key: "alert_email", value }).then((data) => {
          if (value == 0) {
            this.$alert(this.$t("disable_ok"));
          } else {
            this.$alert(this.$t("alert_email_success"));
          }
          //console.log(data);
        });
      },
    },
  },
};
</script>

<style scoped lang="sass">
h3
  padding-bottom: 10px
  text-decoration: underline
  font-size: 16px
</style>
